import React from "react"
import { Link } from "gatsby"
import notFoundStyles from "src/styles/pages/404.scss"

export default function NotFound() {
  return (
    <div className={notFoundStyles.notFound__container}>
      <Link to="/">
        <h1>{"Sorry, couldn't find that page."}</h1>
      </Link>
    </div>
  )
}
